import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import Link from 'src/front/components/Link';
import LoadingScreen from 'src/components/LoadingScreen';
import Helper from 'src/front/helpers/Helper';
import axios from 'axios';
import { API_URI, DAYS, MONTHS, IS_SERVER } from 'src/constants';
import $ from 'jquery';
import MapBox from 'src/front/components/MapBox';
import useLang from 'src/hooks/useLang';
import LeftSidebar from 'src/front/components/LeftSidebar.js';
import RightSidebar from 'src/front/components/RightSidebar.js';
import useHandlePopup, { FrontContext } from 'src/front/helpers/FrontContext';
import DimensionUnitsConvertor from 'src/utils/DimensionUnitsConvertor';
import SvgMap from 'src/components/SvgMaps';
import ComponentStyle from 'src/components/ComponentStyle';
import PageInfoButtons from 'src/front/components/PageInfoButtons';
import InfoBlocks from 'src/front/components/InfoBlocks';
import Sticky from 'src/front/components/Sticky';
import SunPosition from 'src/front/components/SunPosition';

function HomeDefault(props) {
  const [pageData, setPageData] = useState(
    //props.staticContext && props.staticContext.page
    //? props.staticContext.page :
    (typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.page ? window.__REACT_INIT_DATA__.page : null)
  );
  if (typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.page) delete window.__REACT_INIT_DATA__.page;
  const [svgMapSettings, setSvgMapSettings] = useState(null);
  const lang = useLang('Home');
  const [citiesMap, setCitiesMap] = useState('cities');
  const frontContext = useContext(FrontContext);
  const firstRender = useRef(!IS_SERVER);
  const [afterMainInMain, setAfterMainInMain] = useState(false);
  const exportResizeCallback = useRef(null);

  if (firstRender.current) {
    firstRender.current = false;
    document.body.className = 'home';
    frontContext.staticData.setMenuActiveItem(null);
  }

  const loadPageData = useCallback(() => {
    axios
      .get(`${API_URI}/home-page/${lang.currentLanguage._id}/${lang.currentLocalizationIndex}`, { withCredentials: true })
      .then(({ data }) => setPageData(data));
  }, []);

  useEffect(() => {
    loadPageData();
  }, [loadPageData]);

  useEffect(() => {
    if (pageData && lang.loaded) {
      Helper.setSEO(pageData.page, lang, 'page');
      frontContext.staticData.setPageDataTop(pageData.page);

      if (pageData?.svgMapSettings?.svgMapCities?.length) {
        let needUpdate = false;
        myCitieLoop:
        for (let j = 0; j < frontContext.myCities.length; j++) {
          for (let i = 0; i < pageData.svgMapSettings.svgMapCities.length; i++) {
            if (pageData.svgMapSettings.svgMapCities[i]._id == frontContext.myCities[j].id) {
              for (const w in pageData.svgMapSettings.svgMapCities[i].weather) {
                if (pageData.svgMapSettings.svgMapCities[i].weather[w] != frontContext.myCities[j].weather[w]) {
                  frontContext.myCities[j].weather[w] = pageData.svgMapSettings.svgMapCities[i].weather[w];
                  needUpdate = true;
                }
              }
              continue myCitieLoop;
            }
          }
        }
  
        if (needUpdate) frontContext.setProperty('myCities', frontContext.myCities);
      }

      if (pageData?.cities?.constructor == Object && Object.keys(pageData.cities).length) {
        let needUpdate = false;
        myCitieLoop:
        for (let j = 0; j < frontContext.myCities.length; j++) {
          for (const k in pageData.cities) {
            if (pageData.cities[k]._id == frontContext.myCities[j].id) {
              ['icon', 'temp'].forEach((w) => {
                if (pageData.cities[k][w] != frontContext.myCities[j].weather[w]) {
                  frontContext.myCities[j].weather[w] = pageData.cities[k][w];
                  needUpdate = true;
                }
              });
              continue myCitieLoop;
            }
          }
        }
  
        if (needUpdate) frontContext.setProperty('myCities', frontContext.myCities);
      }
    }
  }, [pageData, lang.loaded]);

  useEffect(() => {
    const resizeHandler = () => {
      if (window.innerWidth > 1100) setAfterMainInMain(true);
      else setAfterMainInMain(false);
    };

    setAfterMainInMain(!IS_SERVER && window.innerWidth > 1100);

    window.addEventListener('resize', resizeHandler);

    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  if (!IS_SERVER && (!pageData || !lang.loaded)) return (<><RightSidebar {...props}/><main><LoadingScreen /></main><LeftSidebar {...props}/></>);

  const lastUpdate = !IS_SERVER && new Date(pageData.lastUpdate);

  const cities = [
    { id: 100293825, title: 'shemona', link: 'qiryat_shemona' },
    { id: 100294117, title: 'naharia', link: 'nahariyya' },
    { id: 100293322, title: 'tveria', link: 'tiberias' },
    { id: 100294801, title: 'haifa', link: 'haifa' },
    { id: 100294946, title: 'hadera', link: 'hadera' },
    { id: 100294071, title: 'netania', link: 'netanya' },
    { id: 100293918, title: 'petah_tikva', link: 'petah_tikva' },
    { id: 100293394, title: 'tel_aviv', link: 'tel_aviv' },
    { id: 100293703, title: 'rishon', link: 'rishon_lezion' },
    { id: 100295628, title: 'ashdod', link: 'ashdod' },
    { id: 100295620, title: 'ashkelon', link: 'ashqelon' },
    { id: 100295530, title: 'beersheva', link: 'beersheva' },
    { id: 100293992, title: 'ofaqim', link: 'ofaqim' },
    { id: 100294166, title: 'mizpe_ramon', link: 'mizpe_ramon' },
    { id: 100295277, title: 'ejlat', link: 'eilat' },
    { id: 100281184, title: 'jerusalem', link: 'jerusalem' },
    { id: 100295220, title: 'ein_gedi', link: 'ein_gedi' },
    { id: 106692274, title: 'ein_bokek', link: 'ein_bokek' },
  ];

  const afterMain = <div id="after-main">
    <div className="page-info-text wysiwyg-text" dangerouslySetInnerHTML={{ __html: lang.localizationText(IS_SERVER ? '{print page.page.text}' : pageData.page.text) }}/>
    <InfoBlocks/>
    <PageInfoButtons buttons={['my cities', 'feedback']}/>
  </div>;

  return (
    <>
      <RightSidebar {...props}/>
      <main>
        <Sticky media="1101-*" topFixedElement="header" exportResizeCallback={exportResizeCallback}>
          <ComponentStyle styles={Helper.transformStyles(lang.currentLanguage, [{ src: '/css/home-default.css' }, { src: '/css/home-default-media.css' }])} Preloader={<LoadingScreen/>}>
            <div id="page-cities-map">
              {
                (IS_SERVER || pageData.country) &&
                <>
                  {IS_SERVER && '{if (page.country) :}'}
                  <Link className="switch-map-cities cities" to={lang.l(IS_SERVER ? "{print '/' + page.country.world_part + '/' + page.country.link}" : `/${pageData.country.world_part}/${pageData.country.link}`)}>{lang.t('Show on map')}</Link>
                  <div id="show-current-time-background"></div>
                  <CountryDayData pageData={pageData} lang={lang} exportResizeCallback={exportResizeCallback}/>
                  {IS_SERVER && '{endif}'}
                </>
              }
              <div id="page-cities" className={IS_SERVER ? '{if (!page.svgMapSettings) :}o-hidden{endif}' : (!pageData?.svgMapSettings ? 'o-hidden' : '')}>
                {
                  (IS_SERVER || pageData?.svgMapSettings) &&
                  <>
                    {IS_SERVER && '{if (page.svgMapSettings) :}'}
                    <SvgMap
                      id="home-page-svg-map"
                      regionsSettings={IS_SERVER ? `page.svgMapSettings.svgMapRegions.map((r) => ({ ...r, link: ${lang.l('{print r.link}').replace(/^\{print |\}$/g, '')}, style: { cursor: 'pointer' } }))` : pageData.svgMapSettings.svgMapRegions.map((r) => ({ ...r, link: lang.l(r.link), style: { cursor: 'pointer' } }))}
                      cities={
                        IS_SERVER ?
                        `page.svgMapSettings.svgMapCities.map((c) => ({
                          ...c,
                          link: ${lang.l('{print c.link}').replace(/^\{print |\}$/g, '')},
                          style: { cursor: 'pointer' },
                          weather: { ...c.weather, temp: DimensionUnitsConvertor.temperature(c.weather.temp, dimensionUnits.temperature) }
                        }))` :
                        pageData.svgMapSettings.svgMapCities.map((c) => ({
                          ...c,
                          link: lang.l(c.link),
                          style: { cursor: 'pointer' },
                          weather: { ...c.weather, temp: DimensionUnitsConvertor.temperature(c.weather.temp, frontContext.dimensionUnits.temperature) }
                        }))
                      }
                      fillColor={IS_SERVER ? 'page.svgMapSettings.svgMapRegionColor' : pageData.svgMapSettings.svgMapRegionColor}
                      strokeColor={IS_SERVER ? 'page.svgMapSettings.svgMapBorderColor' : pageData.svgMapSettings.svgMapBorderColor}
                      hoverColor={IS_SERVER ? 'page.svgMapSettings.svgMapRegionHoverColor' : pageData.svgMapSettings.svgMapRegionHoverColor}
                      popupBackground={IS_SERVER ? 'page.svgMapSettings.svgMapPopupBackground' : pageData.svgMapSettings.svgMapPopupBackground}
                      popupTextColor={IS_SERVER ? 'page.svgMapSettings.svgMapPopupText' : pageData.svgMapSettings.svgMapPopupText}
                      popupBorderColor={IS_SERVER ? 'page.svgMapSettings.svgMapPopupBorder' : pageData.svgMapSettings.svgMapPopupBorder}
                      offset={IS_SERVER ? 'page.svgMapSettings.svgMapOffset' : pageData.svgMapSettings.svgMapOffset}
                      style={IS_SERVER ? "{ 'max-height': !('svgMapMaxHeight' in page.svgMapSettings) || page.svgMapSettings.svgMapMaxHeight === '' ? 'none' : page.svgMapSettings.svgMapMaxHeight + 'px' }" : { maxHeight: !('svgMapMaxHeight' in pageData.svgMapSettings) || pageData.svgMapSettings.svgMapMaxHeight === '' ? 'none' : `${pageData.svgMapSettings.svgMapMaxHeight}px` }}
                      citiesSize={IS_SERVER ? 'page.svgMapSettings.svgMapCitiesSize' : pageData.svgMapSettings.svgMapCitiesSize}
                      country={IS_SERVER ? 'page.svgMapSettings.svgMapCountry' : pageData.svgMapSettings.svgMapCountry}
                    />
                    {IS_SERVER && '{endif}'}
                  </>
                }
                {
                  (IS_SERVER || !pageData?.svgMapSettings) &&
                  <>
                    {IS_SERVER && '{if (!page.svgMapSettings) :}'}
                    <div id="home-pogoda-map">
                      <ul>
                        {cities.map((c) => <li key={c.id} id={`home-pogoda-map-city-${c.title}`}>
                          <Link to={lang.l(`/israel/${c.link}`)}>
                            <img alt="Weather" width="284" height="284" className="city-weather" src={IS_SERVER ? `{print Helper.getFileUrl('weather-icon', page.cities[${c.id}].icon + '.svg')}` : Helper.getFileUrl('weather-icon', `${pageData.cities[c.id].icon}.svg`)}/>
                            <span className="city-tempr">{IS_SERVER ? '{print DimensionUnitsConvertor.temperature(page.cities[' + c.id + '].temp, dimensionUnits.temperature)}' : DimensionUnitsConvertor.temperature(pageData.cities[c.id].temp, frontContext.dimensionUnits.temperature)}</span>
                            <span className="city-name">{IS_SERVER ? '{print page.cities[' + c.id + '].name}' : pageData.cities[c.id].name}</span>
                          </Link>
                        </li>)}
                      </ul>
                    </div>
                    {IS_SERVER && '{endif}'}
                  </>
                }
              </div>
            </div>
          </ComponentStyle>
          {afterMainInMain && afterMain}
        </Sticky>
      </main>
      <LeftSidebar {...props}/>
      {!afterMainInMain && afterMain}
    </>
  );
}

// function TimeInLocation({ pageData, lang, exportResizeCallback }) {
//   const frontContext = useContext(FrontContext);
//   let [timeData, setTimeData] = useState(null);
//   const handlePopup = useHandlePopup();
//   const [firstRender, setFirstRender] = useState(true);
//   const timer = useRef(null);
//   const date = new Date();
//   const side = !IS_SERVER && lang.currentLanguage.direction == 'ltr' ? 'left' : 'right';
//   const moonRise = pageData?.moonRise || '00:00';
//   const moonSet = pageData?.moonSet || '00:00';

//   if (!IS_SERVER && !timer.current) {
//     timer.current = setTimeout(() => {
//       const sunRiseDate =  Helper.dateFromFormat(pageData.sunRise, 'H:i').getTime();
//       const sunSetDate = Helper.dateFromFormat(pageData.sunSet, 'H:i').getTime();
//       const sunDiff = sunSetDate - sunRiseDate;
//       const sunCenterX = $('#current-time-sun-animation').width() / 2;
//       const sunCenterY = $('#current-time-sun-animation').height();
//       const sunRadius = $('#current-time-sun-animation').width() / 2 - 3;
  
//       const sunAngle = Date.now() < sunSetDate ? (Date.now() > sunRiseDate ? (Date.now() - sunRiseDate) / sunDiff * Math.PI : 0) : Math.PI;
//       const sunX = sunCenterX - Math.cos(sunAngle) * sunRadius;
//       const sunY = sunCenterY - Math.sin(sunAngle) * sunRadius;
//       const sunLight = $('#current-time-sun-animation-icon-light');
//       const sun = $('#current-time-sun-animation > img');

//       let moonRiseDate = Helper.dateFromFormat(moonRise, 'H:i').getTime();
//       let moonSetDate = Helper.dateFromFormat(moonSet, 'H:i').getTime();
//       if (moonRiseDate >= moonSetDate) {
//         let endOfDay = new Date();
//         endOfDay.setHours(23);
//         endOfDay.setMinutes(59);
//         endOfDay.setSeconds(59);
//         endOfDay.setMilliseconds(59);
//         endOfDay = endOfDay.getTime();

//         let startOfDay = new Date();
//         startOfDay.setHours(0);
//         startOfDay.setMinutes(0);
//         startOfDay.setSeconds(0);
//         startOfDay.setMilliseconds(0);
//         startOfDay = startOfDay.getTime();

//         moonSetDate = new Date(moonSetDate);
//         moonSetDate.setDate(moonSetDate.getDate() + 1);
//         moonSetDate = moonSetDate.getTime();

//         const length1 = Math.min(endOfDay, moonSetDate) - Math.max(startOfDay, moonRiseDate);

//         let moonRiseDate2 = new Date(moonRiseDate);
//         moonRiseDate2.setDate(moonRiseDate2.getDate() - 1);
//         moonRiseDate2 = moonRiseDate2.getTime();
//         let moonSetDate2 = new Date(moonSetDate);
//         moonSetDate2.setDate(moonSetDate2.getDate() - 1);
//         moonSetDate2 = moonSetDate2.getTime();

//         const length2 = Math.min(endOfDay, moonSetDate2) - Math.max(startOfDay, moonRiseDate2);

//         if (length2 > length1) {
//           moonRiseDate = moonRiseDate2;
//           moonSetDate = moonSetDate2;
//         }
//       }
//       const moonDiff = moonRiseDate && moonSetDate ? moonSetDate - moonRiseDate : null;
//       const moonCenterX = $('#current-time-moon-animation').width() / 2;
//       const moonRadius = $('#current-time-moon-animation').width() / 2 - 3;

//       const moonAngle = moonRiseDate && moonSetDate && Date.now() < moonSetDate ? (Date.now() > moonRiseDate ? (Date.now() - moonRiseDate) / moonDiff * Math.PI : 0) : Math.PI;
//       const moonX = moonCenterX + Math.cos(moonAngle) * moonRadius;
//       const moonY = Math.sin(moonAngle) * moonRadius;
//       const moonLight = $('#current-time-moon-animation-icon-light');
//       const moon = $('#current-time-moon-animation > img');
  
//       const date = new Date();
  
//       timer.current = null;

//       setTimeData({
//         sunLight: { display: 'block', [side]: sunX - sunLight.width() / 2, top: sunY - sunLight.height() / 2 },
//         sun: { display: 'block', [side]: sunX - sun.width() / 2, top: sunY - sun.height() / 2 },
//         moonLight: { display: 'block', [side]: moonX - moonLight.width() / 2, top: moonY - moonLight.height() / 2 },
//         moon: { display: 'block', [side]: moonX - moon.width() / 2, top: moonY - moon.height() / 2 },
//         time: Helper.date('H:i:s', Helper.getLocationDate(pageData.timezoneOffset).getTime()),
//         dayLength: timeData.dayLength || lang.t('!h h !m min', { '!h': Helper.firstNumZero(Math.floor(sunDiff / 1000 / 60 / 60)), '!m': Helper.firstNumZero(Math.round((sunDiff % (1000 * 60 * 60)) / 1000 / 60)) }),
//         date: date.getDate(),
//         month: lang.t({ string: MONTHS[date.getMonth()], variant: 1 }),
//       });
//     }, 1000);
//   }

//   const handleOpenTime = (event) => {
//     if (exportResizeCallback.current) {
//       const pageCitiesMap = $('#page-cities-map').closest('.sticky')[0];
//       const { position, top } = pageCitiesMap.style;
//       if (position === 'fixed') exportResizeCallback.current({ forceScrollToBottom: !!top });
//     }
//     handlePopup({ event, element: $('#show-current-time'), name: 'show-current-time' });
//   };

//   useEffect(() => {
//     setFirstRender(false);
//     return () => clearTimeout(timer.current);
//   }, []);

//   if (!IS_SERVER && !timeData) {
//     timeData = {
//       sunLight: {},
//       sun: {},
//       moonLight: {},
//       moon: {},
//       time: $('#show-current-time span')[0]?.innerHTML || Helper.date('H:i:s', Helper.getLocationDate(pageData.timezoneOffset).getTime()),
//       dayLength: null,
//       date: date.getDate(),
//       month: lang.t({ string: MONTHS[date.getMonth()], variant: 1 }),
//     };
//   }

//   return (
//     <div id="show-current-time" data-popup-name="show-current-time" {...(frontContext.popup == 'show-current-time' ? { className: 'opened' } : {})}>
//       <a href="#" className="close-popup" onClick={(event) => handleOpenTime(event)}>
//         <img alt="Weather" width="284" height="284" className="inline-block va-middle" src={IS_SERVER ? "{print Helper.getFileUrl('weather-icon', 'd000.svg')}" : Helper.getFileUrl('weather-icon', 'd000.svg')}/>
//         <div className="inline-block va-middle">
//           <div>{lang.t('Time in !country', { '!country': IS_SERVER ? '{print page.country.name_locative}' : pageData.country.name_locative })}</div>
//           <div>
//             <span className="ltr">{IS_SERVER ? "{print Helper.date('H:i:s', Helper.getLocationDate(page.timezoneOffset).getTime())}" : timeData.time}</span>
//             <svg width="10" height="6" viewBox="0 0 10 6" fill="none">
//               <path d="M1 1L5 5L9 1" stroke="#1F5892" strokeOpacity={frontContext.popup == 'show-current-time' ? 0 : 1} strokeLinecap="round"/>
//               <path d="M1 5L5 1L9 5" stroke="#1F5892" strokeOpacity={frontContext.popup == 'show-current-time' ? 1 : 0} strokeLinecap="round"/>
//             </svg>
//           </div>
//         </div>
//       </a>
//       {
//         !IS_SERVER && !firstRender &&
//         <>
//           <div className="current-time-hidden sun">
//             <div id="current-time-sun-animation">
//               <img alt="Weather" width="284" height="284" loading="lazy" style={timeData.sun} src={Helper.getFileUrl('weather-icon', 'd000.svg')}/>
//               <div id="current-time-sun-animation-icon-light" style={timeData.sunLight}></div>
//               <div id="current-time-sun-animation-date">{timeData.date}</div>
//               <div id="current-time-sun-animation-month">{timeData.month}</div>
//               <div className="daylight-hours" id="current-time-sun-animation-title">{lang.t('Daylight hours')}</div>
//             </div>
//             <div id="current-time-sun-rise-set" className="clear">
//               <div id="current-time-sun-rise">{pageData.sunRise}</div>
//               <div id="current-time-sun-set">{pageData.sunSet}</div>
//               <div id="current-time-sun-rise-day-length">{timeData.dayLength}</div>
//             </div>
//           </div>
//           <div className="current-time-hidden moon">
//             <div id="current-time-moon-rise-set" className="relative">
//               <div id="current-time-moon-rise">{moonRise}</div>
//               <div id="current-time-moon-set">{moonSet}</div>
//               <div id="current-time-moon-description">
//                 <div>{lang.t(pageData.moonPhaseName)}</div>
//                 <div>{lang.t('!day moon day', { '!day': pageData.moonAge })}</div>
//               </div>
//             </div>
//             <div id="current-time-moon-animation">
//               <img alt="Weather" width="284" height="284" loading="lazy" style={timeData.moon} src={Helper.getFileUrl('weather-icon', `${pageData.moonPhaseIcon}.svg`)}/>
//               <div style={timeData.moonLight} id="current-time-moon-animation-icon-light"></div>
//             </div>
//           </div>
//           <a href="#" className="current-time-hidden close-popup"></a>
//         </>
//       }
//     </div>
//   );
// }

function CountryDayData({ pageData, lang, exportResizeCallback }) {
  const frontContext = useContext(FrontContext);
  const handlePopup = useHandlePopup();
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    setFirstRender(false);
  }, []);

  const handleOpenTime = (event) => {
    if (exportResizeCallback.current) {
      const pageCitiesMap = $('#page-cities-map').closest('.sticky')[0];
      const { position, top } = pageCitiesMap.style;
      if (position === 'fixed') exportResizeCallback.current({ forceScrollToBottom: !!top });
    }
    handlePopup({ event, element: $('#show-current-time'), name: 'show-current-time' });
  };

  return (
    <div id="show-current-time" data-popup-name="show-current-time" {...(frontContext.popup == 'show-current-time' ? { className: 'opened' } : {})}>
      <a href="#" className="close-popup" onClick={(event) => handleOpenTime(event)}>
        <img alt="Weather" width="284" height="284" className="inline-block va-middle" src={IS_SERVER ? "{print Helper.getFileUrl('weather-icon', 'd000.svg')}" : Helper.getFileUrl('weather-icon', 'd000.svg')}/>
        <div className="inline-block va-middle">
          <div>{lang.t('Time in !country', { '!country': IS_SERVER ? '{print page.country.name_locative}' : pageData.country.name_locative })}</div>
          <div>
            <TimeInCountry timezoneOffset={pageData?.timezoneOffset}/>
            <svg width="10" height="6" viewBox="0 0 10 6" fill="none">
              <path d="M1 1L5 5L9 1" stroke="#1F5892" strokeOpacity={frontContext.popup == 'show-current-time' ? 0 : 1} strokeLinecap="round"/>
              <path d="M1 5L5 1L9 5" stroke="#1F5892" strokeOpacity={frontContext.popup == 'show-current-time' ? 1 : 0} strokeLinecap="round"/>
            </svg>
          </div>
        </div>
      </a>
      {
        !IS_SERVER && !firstRender &&
        <>
          <div className="current-time-hidden sun">
            <SunPosition sunRise={pageData.sunRise} sunSet={pageData.sunSet} timezoneOffset={pageData.timezoneOffset}/>
          </div>
          <MoonPosition
            lang={lang}
            moonRise={pageData.moonRise}
            moonSet={pageData.moonSet}
            moonPhaseName={pageData.moonPhaseName}
            moonAge={pageData.moonAge}
            moonPhaseIcon={pageData.moonPhaseIcon}
          />
          <a href="#" className="current-time-hidden close-popup"></a>
        </>
      }
    </div>
  );
}

function TimeInCountry({ timezoneOffset }) {
  let [time, setTime] = useState(null);
  const timer = useRef(null);

  useEffect(() => () => clearTimeout(timer.current), []);

  if (!IS_SERVER && !timer.current) {
    timer.current = setTimeout(() => {
      timer.current = null;

      setTime(Helper.date('H:i:s', Helper.getLocationDate(timezoneOffset).getTime()));
    }, 1000);
  }

  if (!IS_SERVER && !time) time = $('#show-current-time span')[0]?.innerHTML || Helper.date('H:i:s', Helper.getLocationDate(timezoneOffset).getTime());

  return (
    <span className="ltr">{IS_SERVER ? "{print Helper.date('H:i:s', Helper.getLocationDate(page.timezoneOffset).getTime())}" : time}</span>
  );
}

function MoonPosition({ lang, moonRise, moonSet, moonPhaseName, moonAge, moonPhaseIcon }) {
  moonRise = moonRise || '00:00';
  moonSet = moonSet || '00:00';

  const timer = useRef(null);
  const side = !IS_SERVER && lang.currentLanguage.direction == 'ltr' ? 'left' : 'right';
  let [data, setData] = useState(null);

  if (!IS_SERVER && !timer.current) {
    timer.current = setTimeout(() => {
      let moonRiseDate = Helper.dateFromFormat(moonRise, 'H:i').getTime();
      let moonSetDate = Helper.dateFromFormat(moonSet, 'H:i').getTime();
      if (moonRiseDate >= moonSetDate) {
        let endOfDay = new Date();
        endOfDay.setHours(23);
        endOfDay.setMinutes(59);
        endOfDay.setSeconds(59);
        endOfDay.setMilliseconds(59);
        endOfDay = endOfDay.getTime();

        let startOfDay = new Date();
        startOfDay.setHours(0);
        startOfDay.setMinutes(0);
        startOfDay.setSeconds(0);
        startOfDay.setMilliseconds(0);
        startOfDay = startOfDay.getTime();

        moonSetDate = new Date(moonSetDate);
        moonSetDate.setDate(moonSetDate.getDate() + 1);
        moonSetDate = moonSetDate.getTime();

        const length1 = Math.min(endOfDay, moonSetDate) - Math.max(startOfDay, moonRiseDate);

        let moonRiseDate2 = new Date(moonRiseDate);
        moonRiseDate2.setDate(moonRiseDate2.getDate() - 1);
        moonRiseDate2 = moonRiseDate2.getTime();
        let moonSetDate2 = new Date(moonSetDate);
        moonSetDate2.setDate(moonSetDate2.getDate() - 1);
        moonSetDate2 = moonSetDate2.getTime();

        const length2 = Math.min(endOfDay, moonSetDate2) - Math.max(startOfDay, moonRiseDate2);

        if (length2 > length1) {
          moonRiseDate = moonRiseDate2;
          moonSetDate = moonSetDate2;
        }
      }
      const moonDiff = moonRiseDate && moonSetDate ? moonSetDate - moonRiseDate : null;
      const moonCenterX = $('#current-time-moon-animation').width() / 2;
      const moonRadius = $('#current-time-moon-animation').width() / 2 - 3;

      const moonAngle = moonRiseDate && moonSetDate && Date.now() < moonSetDate ? (Date.now() > moonRiseDate ? (Date.now() - moonRiseDate) / moonDiff * Math.PI : 0) : Math.PI;
      const moonX = moonCenterX + Math.cos(moonAngle) * moonRadius;
      const moonY = Math.sin(moonAngle) * moonRadius;
      const moonLight = $('#current-time-moon-animation-icon-light');
      const moon = $('#current-time-moon-animation > img');
  
      timer.current = null;

      setData({
        moonLight: { display: 'block', [side]: moonX - moonLight.width() / 2, top: moonY - moonLight.height() / 2 },
        moon: { display: 'block', [side]: moonX - moon.width() / 2, top: moonY - moon.height() / 2 },
      });
    }, 1000);
  }

  useEffect(() => () => clearTimeout(timer.current), []);

  if (!IS_SERVER && !data) {
    data = {
      moonLight: {},
      moon: {},
    };
  }

  return (
    <div className="current-time-hidden moon">
      <div id="current-time-moon-rise-set" className="relative">
        <div id="current-time-moon-rise">{moonRise}</div>
        <div id="current-time-moon-set">{moonSet}</div>
        <div id="current-time-moon-description">
          <div>{lang.t(moonPhaseName)}</div>
          <div>{lang.t('!day moon day', { '!day': moonAge })}</div>
        </div>
      </div>
      <div id="current-time-moon-animation">
        <img alt="Weather" width="284" height="284" loading="lazy" style={data.moon} src={Helper.getFileUrl('weather-icon', `${moonPhaseIcon}.svg`)}/>
        <div style={data.moonLight} id="current-time-moon-animation-icon-light"></div>
      </div>
    </div>
  );
}

export default HomeDefault;